<template>
  <div>
    <div
      v-if="!isInWebView"
      id="google-continue"
      class="g_id_signin"
      dir="auto"
    ></div>
    <div v-else>
      <button
        class="google-sign-in-btn"
        @click="requestGoogleSignIn"
      >
        <img
          src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg"
          alt="Google logo"
          class="google-logo"
        />
        <span class="btn-text">{{ $t('googleSignInButton') }}</span>
      </button>
    </div>
  </div>
</template>

<script>
import useEventListeners from '@/composables/useEventListeners'
import { i18n } from '@/plugins/i18n/index'
import store from '@/store'
import { authenticate } from '@/views/globalHelpers'
import axios from '@axios'
import { onMounted, onUnmounted, ref, watch } from '@vue/composition-api'

export default {
  setup() {
    const { addListener, removeAllListenersForEvent } = useEventListeners()

    const googleLoaded = ref(false)
    const isInWebView = ref(store.getters['app/getIsInWebView'])

    const renderGoogleButton = () => {
      window.google.accounts.id.renderButton(document.getElementById('google-continue'), {
        theme: 'outline',
        size: 'large',
        locale: i18n.locale,
        text: 'sign_in_with',
        type: 'standard',
        logo_alignment: 'left',
        shape: 'pill',
      })
    }

    const handleGoogleAuth = async token => {
      authenticate(axios.post('google-authentication', { token }), () => {})
    }

    const handleGoogleSignIn = user => {
      handleGoogleAuth(user.credential)
    }

    const initializeGsi = () => {
      if (!window.google || googleLoaded.value) return

      window.google.accounts.id.initialize({
        client_id: process.env.VUE_APP_GOOGLE_CLIENT_ID,
        callback: handleGoogleSignIn,
      })

      window.google.accounts.id.prompt()
      renderGoogleButton()
      googleLoaded.value = true
    }

    const requestGoogleSignIn = () => {
      // Send a message to the React Native app to request Google Sign-In
      window.ReactNativeWebView.postMessage(JSON.stringify({ type: 'REQUEST_GOOGLE_SIGN_IN' }))
    }

    onMounted(() => {
      if (!isInWebView.value) {
        const script = document.createElement('script')
        script.src = 'https://accounts.google.com/gsi/client'
        script.onload = initializeGsi
        script.async = true
        script.id = 'google-client-script'
        document.querySelector('body').appendChild(script)
      }

      addListener('GOOGLE_SIGN_IN_RESPONSE', event => {
        const data = event.detail
        if (data.token) {
          handleGoogleAuth(data.token)
        } else {
          console.error('Google sign-in failed', data.message)
        }
      })
    })

    onUnmounted(() => {
      removeAllListenersForEvent('GOOGLE_SIGN_IN_RESPONSE')
    })

    watch(
      () => i18n.locale,
      () => {
        if (!isInWebView.value && googleLoaded.value) {
          renderGoogleButton()
        }
      },
    )

    return {
      isInWebView,
      requestGoogleSignIn,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '~vuetify/src/styles/styles.sass';

.google-sign-in-btn {
  background-color: #ffffff;
  border: 1px solid #dadce0;
  color: #3c4043;
  font-size: 14px;
  padding: 10px 40px;
  border-radius: 4px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  border-radius: 50px;
}

.google-sign-in-btn:hover {
  background-color: #f1f3f4;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

.google-logo {
  width: 20px;
  height: 20px;
  @include ltr() {
    margin-right: 12px;
  }
  @include rtl() {
    margin-left: 12px;
  }
}

.btn-text {
  font-weight: 500;
  color: #3c4043;
  font-size: 12px;
}
</style>
